export default [
  {
    key: 'surfer-activity',
    title: 'Surfer Activity',
    icon: 'img:icons/fluent/bar-chart.svg',
    permissions: ['access:surfer-activity'],
    children: [
      {
        key: 'surfer-activity-surfer-activity',
        path: 'surfer-activity/surfer-activity',
        title: 'Surfer Activity',
        icon: 'img:/icons/line-chart.svg'
      }
    ]
  }
]
