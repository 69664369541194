export default [
  {
    key: 'finauto',
    title: 'Finance Automation',
    icon: 'img:icons/color/transaction.svg',
    path: 'finauto',
    permissions: [
      'access:finauto'
    ],
    children: [
      { // Pages
        key: 'finauto-vat-rates',
        path: 'finauto/vat_rates',
        title: 'VAT Rates',
        icon: 'img:icons/handdrawn/general-ledger.svg'
      },
      {
        key: 'finauto-media-vat-rates',
        path: 'finauto/media_vat_rates',
        title: 'Media VAT Rates',
        icon: 'img:icons/color/video-playlist.svg'
      },
      {
        key: 'finauto-tax-accrual',
        path: 'finauto/tax_accrual',
        title: 'Tax Accrual',
        icon: 'img:icons/milkbox/cash-1.svg'
      },
      // Opti
      {
        key: 'opti-prices',
        path: 'finauto/opti_prices',
        title: 'Prices',
        icon: 'img:icons/milkbox/cash-1.svg'
      },
      {
        key: 'opti-pricelists',
        path: 'finauto/opti_pricelists',
        title: 'Pricelists',
        icon: 'img:icons/milkbox/cash-1.svg'
      },
      {
        key: 'opti-offers',
        path: 'finauto/opti_offers',
        title: 'Offers',
        icon: 'img:icons/milkbox/cash-1.svg'
      }
    ]
  }
]
