export default [
  { path: 'msb/recipes/:id?', component: () => import('./pages/Recipes.vue') },
  { path: 'msb/templates/:id?/:section?', component: () => import('./pages/Templates.vue') },
  { path: 'msb/content/:id?/:section?', component: () => import('./pages/Content.vue') },
  { path: 'msb/sections/:id?/:section?', component: () => import('./pages/Sections.vue') },
  { path: 'msb/monitoring/:id?/:section?', component: () => import('./pages/Monitoring.vue') },
  { path: 'msb/sites/:id?/:section?', component: () => import('./pages/Sites.vue') },
  { path: 'msb/publishing/:id?', component: () => import('./pages/Publishing.vue') },
  { path: 'msb/components/:id?/:component?', component: () => import('./pages/Components.vue') },
  { path: 'msb/payments/:id?/:component?', component: () => import('./pages/Payments.vue') },
  { path: 'msb/metrics/', component: () => import('./pages/Metrics.vue') }
]
