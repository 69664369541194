import axios from 'axios'
import FormData from 'form-data'

const state = {
  api: {
    endpoints: {
      local: 'https://6h8pxwrdqi.execute-api.us-east-1.amazonaws.com/staging/api/v1',
      dev: 'https://6h8pxwrdqi.execute-api.us-east-1.amazonaws.com/staging/api/v1',
      staging: 'https://6h8pxwrdqi.execute-api.us-east-1.amazonaws.com/staging/api/v1',
      prod: 'https://gsjp5oiw8l.execute-api.us-east-1.amazonaws.com/production/api/v1'
    }
  }
}

export default {
  namespaced: true,
  state,
  actions: {
    async importWeights ({ state, rootState }, { file }) {
      const user = rootState.auth.user
      const data = new FormData()
      data.append('submitter', user.email)
      data.append('', file)
      const accessToken = await window.auth0Client.getTokenSilently()
      const env = rootState.site.env.id
      const endpoint = state.api.endpoints[env]

      const request = {
        method: 'POST',
        url: `${endpoint}/multi_dimension/csv`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          ...(data.getHeaders?.() ?? { 'Content-Type': 'multipart/form-data' })
        },
        data
      }
      console.log('request', request)

      try {
        const response = await axios(request)
        console.log('response', response)
        return response.data
      } catch (error) {
        if (error.response) {
          throw new Error(`${error.response.status}: ${error.response.data.message}`)
        }
        throw error
      }
    },
    getEndpoint ({ state, rootState }) {
      const env = rootState.site.env.id
      return state.api.endpoints[env]
    }
  }
}
