import { make } from 'vuex-pathify'
import axios from 'axios'

const state = {
  cdn: {
    templates: {
      endpoints: {
        local: 'https://template-staging.milkbox.systems',
        dev: 'https://template-staging.milkbox.systems',
        staging: 'https://template-staging.milkbox.systems',
        prod: 'https://template.milkbox.systems'
      }
    },
    template_blocks: {
      endpoints: {
        local: 'https://block-staging.milkbox.systems',
        dev: 'https://block-staging.milkbox.systems',
        staging: 'https://block-staging.milkbox.systems',
        prod: 'https://block.milkbox.systems'
      }
    },
    assets: {
      endpoints: {
        local: 'https://assets-staging.milkbox.systems',
        dev: 'https://assets-staging.milkbox.systems',
        staging: 'https://assets-staging.milkbox.systems',
        prod: 'https://assets.milkbox.systems'
      }
    },
    funnels_primary: {
      endpoints: {
        local: 'https://f.easyfunnel.dev',
        dev: 'https://f.easyfunnel.dev',
        staging: 'https://f.easyfunnel.dev',
        prod: 'https://f.easyfunnel.biz'
      }
    },
    funnels_secondary: {
      endpoints: {
        local: 'https://f-aws.easyfunnel.dev',
        dev: 'https://f-aws.easyfunnel.dev',
        staging: 'https://f-aws.easyfunnel.dev',
        prod: 'https://f-aws.easyfunnel.biz'
      }
    }
  },
  api: {
    portal: {
      endpoints: {
        local: 'https://staging-mb-portal.milkbox.systems',
        dev: 'https://staging-mb-portal.milkbox.systems',
        staging: 'https://staging-mb-portal.milkbox.systems',
        prod: 'https://mb-portal.milkbox.systems'
      }
    },
    operator: {
      endpoints: {
        local: 'https://staging-mb-api-operator.milkbox.systems/auth0',
        dev: 'https://staging-mb-api-operator.milkbox.systems/auth0',
        staging: 'https://staging-mb-api-operator.milkbox.systems/auth0',
        prod: 'https://production-mb-api-operator.milkbox.systems/auth0'
      }
    },
    funnels: {
      endpoints: {
        local: 'https://staging-mb-api-funnels.milkbox.systems/auth0',
        dev: 'https://staging-mb-api-funnels.milkbox.systems/auth0',
        staging: 'https://staging-mb-api-funnels.milkbox.systems/auth0',
        prod: 'https://production-mb-api-funnels.milkbox.systems/auth0'
      }
    },
    recipes: {
      endpoints: {
        local: 'https://staging-mb-api-recipes.milkbox.systems/auth0',
        dev: 'https://staging-mb-api-recipes.milkbox.systems/auth0',
        staging: 'https://staging-mb-api-recipes.milkbox.systems/auth0',
        prod: 'https://production-mb-api-recipes.milkbox.systems/auth0'
      }
    },
    templates: {
      endpoints: {
        local: 'https://staging-mb-api-templates.milkbox.systems/auth0',
        dev: 'https://staging-mb-api-templates.milkbox.systems/auth0',
        staging: 'https://staging-mb-api-templates.milkbox.systems/auth0',
        prod: 'https://production-mb-api-templates.milkbox.systems/auth0'
      }
    },
    subscriptions: {
      endpoints: {
        local: 'https://development-mb-api-subscription.milkbox.dev/auth0',
        dev: 'https://development-mb-api-subscription.milkbox.dev/auth0',
        staging: 'https://staging-mb-api-subscription.milkbox.systems/auth0',
        prod: 'https://production-mb-api-subscription.milkbox.systems/auth0'
      }
    },
    documentation: {
      endpoints: {
        local: 'https://staging-mb-api-documentation.milkbox.systems',
        dev: 'https://staging-mb-api-documentation.milkbox.systems',
        staging: 'https://staging-mb-api-documentation.milkbox.systems',
        prod: 'https://production-mb-api-documentation.milkbox.systems'
      }
    },
    segmentation: {
      endpoints: {
        local: 'https://development-mb-api-segmentation.milkbox.dev/auth0',
        dev: 'https://development-mb-api-segmentation.milkbox.dev/auth0',
        staging: 'https://staging-mb-api-segmentation.milkbox.systems/auth0',
        prod: 'https://production-mb-api-segmentation.milkbox.systems/auth0'
      }
    },
    firebase: {
      endpoints: {
        local: 'https://development-mfb-firebase-notifications.milkbox.dev/auth0',
        dev: 'https://development-mfb-firebase-notifications.milkbox.dev/auth0',
        staging: 'https://staging-mfb-firebase-notifications.milkbox.systems/auth0',
        prod: 'https://production-mfb-firebase-notifications.milkbox.systems/auth0'
      }
    },
    members: {
      endpoints: {
        local: 'https://staging-mb-api-members.milkbox.systems/auth0',
        dev: 'https://staging-mb-api-members.milkbox.systems/auth0',
        staging: 'https://staging-mb-api-members.milkbox.systems/auth0',
        prod: 'https://production-mb-api-members.milkbox.systems/auth0'
      }
    },
    xero: {
      endpoints: {
        local: 'http://localhost:3000',
        dev: 'https://development-mb-api-xero.milkbox.dev/auth0',
        staging: 'https://staging-mb-api-xero.milkbox.systems/auth0',
        prod: 'https://production-mb-api-xero.milkbox.systems/auth0'
      }
    },
    reporting: {
      endpoints: {
        local: 'http://localhost:3000',
        dev: 'https://development-mb-api-analytics.milkbox.dev/auth0',
        staging: 'https://staging-mb-api-analytics.mb-tracking.com/auth0',
        prod: 'https://production-mb-api-analytics.mb-tracking.com/auth0'
      }
    },
    domains: {
      endpoints: {
        local: 'http://localhost:3000',
        dev: 'https://development-mb-api-domains.milkbox.dev/auth0',
        staging: 'https://staging-mb-api-domains.milkbox.systems/auth0',
        prod: 'https://production-mb-api-domains.milkbox.systems/auth0'
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters: {
    templateCdnEndpoint (state, getters, rootState, rootGetters) {
      const env = rootState.site.env.id
      return state.cdn.templates.endpoints[env]
    },
    templateBlockCdnEndpoint (state, getters, rootState, rootGetters) {
      const env = rootState.site.env.id
      return state.cdn.template_blocks.endpoints[env]
    },
    assetsCdnEndpoint (state, getters, rootState, rootGetters) {
      const env = rootState.site.env.id
      return state.cdn.assets.endpoints[env]
    }
  },
  mutations: {
    ...make.mutations(state)
  },
  actions: {
    /**
     *
     * Portal
     */
    async callPortal ({ commit, state, rootState }, { method, path, body, endpointOverride = null, environmentOverride = null }) {
      if (!body) {
        body = {}
      }

      const env = environmentOverride !== null ? environmentOverride : rootState.site.env.id
      const accessToken = await window.auth0Client.getTokenSilently()
      const endpoint = state.api.portal.endpoints[env]

      const request = {
        method: method,
        url: endpointOverride !== null ? `${endpointOverride}${path}` : `${endpoint}${path}`,
        data: body,
        headers: {
          'content-type': 'application/json',
          'Milkbox-Authorization': `Bearer ${accessToken}`
        }
      }

      return axios(request).then(response => response.data)
    },
    /**
     *
     * CRUD API Operations
     */
    async fetchAllFromAPI ({ dispatch, state, commit, rootState }, { name, resource, limit = 10, offset = 0, sortBy = null, sortDirection = 'asc', attributes = null, q = null, status = null /* used only for Xero */, environmentOverride = null, xeroContactId = null, params = null /* used only for Xero */ }) {
      if (!state.api[name]) {
        throw new Error(`Unknown API [${name}]`)
      }

      const env = environmentOverride !== null ? environmentOverride : rootState.site.env.id
      const token = await window.auth0Client.getTokenSilently()

      let query = `?limit=${limit}&offset=${offset}`
      if (sortBy) {
        query += `&sort_by=${sortBy}&sort_direction=${sortDirection}`
      }
      if (attributes && attributes.length) {
        query += `&attributes=${attributes.toString()}`
      }
      if (q) {
        query += `&q=${JSON.stringify(q)}`
      }
      if (params) {
        query += `&${params}`
      }
      if (status && status.length) { // Used only for the Xero API
        query += `&status=${status.toString()}`
      }
      if (xeroContactId && xeroContactId.length) { // Used only for the Xero API
        query += `&contactId=${xeroContactId.toString()}`
      }

      console.log('url', encodeURI(`${state.api[name].endpoints[env]}/${resource}${query}`))

      return axios({
        method: 'get',
        headers: {
          'Milkbox-Authorization': `Bearer ${token}`
        },
        url: encodeURI(`${state.api[name].endpoints[env]}/${resource}${query}`)
      }).then(result => { return { data: result.data, total: result.headers['x-total-count'] } })
    },

    async getSpecificFromAPI ({ dispatch, state, commit, rootState }, { name, resource, id, environmentOverride = null }) {
      if (!state.api[name]) {
        throw new Error(`Unknown API [${name}]`)
      }
      const env = environmentOverride !== null ? environmentOverride : rootState.site.env.id
      const token = await window.auth0Client.getTokenSilently()

      return axios({
        method: 'get',
        headers: {
          'Milkbox-Authorization': `Bearer ${token}`
        },
        url: encodeURI(`${state.api[name].endpoints[env]}/${resource}/${id}`)
      }).then(result => result.data)
    },

    async updateSpecificFromAPI ({ dispatch, state, commit, rootState }, { name, resource, id, data, environmentOverride = null }) {
      if (!state.api[name]) {
        throw new Error(`Unknown API [${name}]`)
      }
      const env = environmentOverride !== null ? environmentOverride : rootState.site.env.id
      const token = await window.auth0Client.getTokenSilently()

      return axios({
        method: 'patch',
        headers: {
          'Milkbox-Authorization': `Bearer ${token}`
        },
        url: encodeURI(`${state.api[name].endpoints[env]}/${resource}/${id}`),
        data: data
      }).then(result => result.data)
    },

    async createOneFromAPI ({ dispatch, state, commit, rootState }, { name, resource, data, environmentOverride = null }) {
      if (!state.api[name]) {
        throw new Error(`Unknown API [${name}]`)
      }
      const env = environmentOverride !== null ? environmentOverride : rootState.site.env.id
      const token = await window.auth0Client.getTokenSilently()

      return axios({
        method: 'POST',
        headers: {
          'Milkbox-Authorization': `Bearer ${token}`
        },
        url: encodeURI(`${state.api[name].endpoints[env]}/${resource}`),
        data: data
      }).then(result => result.data)
    },

    async deleteFromAPI ({ dispatch, state, commit, rootState }, { name, resource, id, environmentOverride = null }) {
      if (!state.api[name]) {
        throw new Error(`Unknown API [${name}]`)
      }
      const env = environmentOverride !== null ? environmentOverride : rootState.site.env.id
      const token = await window.auth0Client.getTokenSilently()

      return axios.delete(encodeURI(`${state.api[name].endpoints[env]}/${resource}/${id}`), { headers: { 'Milkbox-Authorization': `Bearer ${token}` } }).then(result => result.data)
    },

    async customEndpointFromAPI ({ dispatch, state, commit, rootState }, { name, method, path, data, environmentOverride = null }) {
      if (!state.api[name]) {
        throw new Error(`Unknown API [${name}]`)
      }
      const env = environmentOverride !== null ? environmentOverride : rootState.site.env.id
      const token = await window.auth0Client.getTokenSilently()

      return axios({
        method: method,
        headers: {
          'Milkbox-Authorization': `Bearer ${token}`
        },
        url: encodeURI(`${state.api[name].endpoints[env]}${path}`),
        data: data
      }).then(result => result.data)
    },

    /**
     *
     * Reporting
     */
    async runReport ({ dispatch, state, commit, rootState }, { type, resource, id, payload = null }) {
      const env = rootState.site.env.id
      const token = await window.auth0Client.getTokenSilently()

      if (type === 'run') {
        return axios({
          method: 'POST',
          headers: {
            'Milkbox-Authorization': `Bearer ${token}`
          },
          url: encodeURI(`${state.api.reporting.endpoints[env]}/${type}/${resource}`),
          data: payload
        }).then(result => result.data)
      }

      const compareFunnelId = payload.compare_funnel_id
      let query = `?start=${payload.start_date.toISOString()}&end=${payload.end_date.toISOString()}&include_internal=${payload.include_internal}`

      if (type === 'report') {
        if (payload.compare_start && payload.compare_end) {
          query += `&compare_start=${payload.compare_start.toISOString()}&compare_end=${payload.compare_end.toISOString()}`
        } else if (compareFunnelId) {
          query += `&compare_funnel_id=${compareFunnelId}`
        }
        if (payload.break_by) {
          query += `&break_by=${payload.break_by}`
        }
      } else if (type === 'graph') {
        if (compareFunnelId) {
          query += `&compare_funnel_id=${compareFunnelId}`
        } else if (payload.compare_start && payload.compare_end) {
          query += `&compare_start=${payload.compare_start.toISOString()}&compare_end=${payload.compare_end.toISOString()}`
        }
        query += `&metric=${payload.metric}&period_in_seconds=${payload.period_in_seconds}`
        if (payload.filter) {
          query += `&filter=${payload.filter}`
        }
      } else {
        throw new Error(`Unknown report type [${type}]`)
      }
      return axios({
        method: 'get',
        headers: {
          'Milkbox-Authorization': `Bearer ${token}`
        },
        url: encodeURI(`${state.api.reporting.endpoints[env]}/${type}/${resource}/${id}${query}`)
      }).then(result => result.data)
    },

    async surferActivityReportAsync ({ dispatch, state, commit, rootState }, { startDate, endDate, startCompareDate, endCompareDate, includeInternal = false, breakBy = null, filter = null, pagination = { page: 0, sortBy: 'joins', sortOrder: -1, appendDataCallbackFn: null } }) {
      const env = rootState.site.env.id
      const token = await window.auth0Client.getTokenSilently()

      let query = buildCommonQueryStringForReportAndGraph(startDate, endDate, startCompareDate, endCompareDate, filter)
      if (includeInternal) query += `&include_internal=${includeInternal}`
      if (breakBy) query += `&break_by=${breakBy}`
      if (pagination) {
        if (pagination.sortBy) query += `&sortBy=${pagination.sortBy}`
        if (pagination.sortOrder) query += `&sortOrder=${pagination.sortOrder}`
      }

      const url = `${state.api.reporting.endpoints[env]}/surfer-activity-async-v2${query}`
      return axios({
        method: 'GET',
        headers: {
          'Milkbox-Authorization': `Bearer ${token}`
        },
        url: encodeURI(url)
      })
    },

    async surferActivityReportAsyncResults ({ dispatch, state, commit, rootState }, { requestId, pagination = { page: 0, sortBy: 'joins', sortOrder: -1, appendDataCallbackFn: null } }) {
      const env = rootState.site.env.id
      const token = await window.auth0Client.getTokenSilently()

      let query = `requestId=${requestId}`
      if (pagination) {
        if (pagination.sortBy) query += `&sortBy=${pagination.sortBy}`
        if (pagination.sortOrder) query += `&sortOrder=${pagination.sortOrder}`
      }

      const url = `${state.api.reporting.endpoints[env]}/surfer-activity-async-results?${query}`
      return axios({
        method: 'GET',
        headers: {
          'Milkbox-Authorization': `Bearer ${token}`
        },
        url: encodeURI(url)
      })
    },

    async surferActivityReport ({ dispatch, state, commit, rootState }, { startDate, endDate, startCompareDate, endCompareDate, includeInternal = false, breakBy = null, filter = null, pagination = { page: 0, sortBy: 'joins', sortOrder: -1, appendDataCallbackFn: null } }) {
      const env = rootState.site.env.id
      const token = await window.auth0Client.getTokenSilently()

      let query = buildCommonQueryStringForReportAndGraph(startDate, endDate, startCompareDate, endCompareDate, filter)
      if (includeInternal) query += `&include_internal=${includeInternal}`
      if (breakBy) query += `&break_by=${breakBy}`
      if (pagination) {
        if (pagination.sortBy) query += `&sortBy=${pagination.sortBy}`
        if (pagination.sortOrder) query += `&sortOrder=${pagination.sortOrder}`
      }

      const url = `${state.api.reporting.endpoints[env]}/surfer-activity${query}`
      return axios({
        method: 'GET',
        headers: {
          'Milkbox-Authorization': `Bearer ${token}`
        },
        url: encodeURI(url)
      }).then(async (result) => await getMorePagesIfAny(result, url, pagination))

      /**
       * Recursively keeps getting paged data for the given request.
       * Calls the callback/append function for each page except the last.
       * The last page is returned as the function value
       * @param {Object} result Axios response object with data (and headers)
       * @param {string} url The request that was tried
       * @param {object} pagination Object with pagination info and callback function
       * @returns The data of the last page
       */
      async function getMorePagesIfAny (result, url, pagination) {
        const currentPage = (pagination.page || 0)

        if (!result.headers['x-total-pages']) {
          return result.data
        }

        if (currentPage < (Number(result.headers['x-total-pages']) - 1)) {
          // Call append function with current data and fetch next page
          await pagination.appendDataCallbackFn(result.data)

          pagination.page = currentPage + 1
          return await axios({
            method: 'GET',
            headers: {
              'Milkbox-Authorization': `Bearer ${token}`
            },
            url: encodeURI(url + `&page=${pagination.page}`)
          }).then(async (result) => await getMorePagesIfAny(result, url, pagination))
        }

        // Returning the data for the last page
        return result.data
      }
    },

    async surferActivityGraph ({ dispatch, state, commit, rootState }, { startDate, endDate, metric, periodInSeconds, startCompareDate, endCompareDate, funnelId, filter = null, includeInternal = false }) {
      const env = rootState.site.env.id
      const token = await window.auth0Client.getTokenSilently()

      let query = buildCommonQueryStringForReportAndGraph(startDate, endDate, startCompareDate, endCompareDate, filter)
      query += `&include_internal=${includeInternal}`
      query += `&metric=${metric}&period_in_seconds=${periodInSeconds}`

      let url = `${state.api.reporting.endpoints[env]}/graph`
      if (funnelId) {
        url = url += `/funnel/${funnelId}`
      }

      return axios({
        method: 'GET',
        headers: {
          'Milkbox-Authorization': `Bearer ${token}`
        },
        url: encodeURI(`${url}${query}`)
      }).then(result => result.data)
    }
  }
}

function buildCommonQueryStringForReportAndGraph (startDate, endDate, startCompareDate, endCompareDate, filter) {
  let query = `?start=${startDate.toISOString()}&end=${endDate.toISOString()}`
  if (startCompareDate && endCompareDate) {
    query += `&compare_start=${startCompareDate.toISOString()}&compare_end=${endCompareDate.toISOString()}`
  }
  if (filter) {
    function buildWhereFromFilter (filter) {
      if (filter.operand === null) {
        return `${filter.dimension} is null`
      } else {
        if (filter.operator === 'LIKE') {
          return `${filter.dimension} LIKE '${filter.operand}'`
        } else if (filter.operator === 'NOT LIKE') {
          return `${filter.dimension} NOT LIKE '${filter.operand}'`
        } else {
          return `${filter.dimension}='${filter.operand}'`
        }
      }
    }
    // Convert filter into a string for the where clause
    const whereArr = filter.map(clause => buildWhereFromFilter(clause))
    query += `&filter=${whereArr.join(' AND ')}`
  }
  return query
}
