import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import find from 'lodash/find'

Vue.use(VueApollo)

export default ({ app, store }) => {
  // Get Saved Environment
  const savedEnvId = window.localStorage.getItem('mc_env')
  if (savedEnvId) {
    store.set('site/env', find(store.get('site/envs'), ['id', savedEnvId]))
  }

  // Dynamic GraphQL URI Link
  const httpLink = new BatchHttpLink({
    uri () { return store.get('site/graphEndpoint') }
  })

  // Authentication Link
  const authLink = setContext(async (req, { headers }) => {
    const token = await window.auth0Client.getTokenSilently()
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  })

  window.apollo = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            missioncontrol: {
              merge: true
            },
            eventsTracking: {
              merge: true
            }
          }
        }
      }
    }),
    link: authLink.concat(httpLink),
    credentials: 'omit'
  })

  // Init Vue Apollo
  app.apolloProvider = new VueApollo({
    defaultClient: window.apollo
  })
}
