import { render, staticRenderFns } from "./Sidenav.vue?vue&type=template&id=676b8fc4&lang=pug&"
import script from "./Sidenav.vue?vue&type=script&lang=js&"
export * from "./Sidenav.vue?vue&type=script&lang=js&"
import style0 from "./Sidenav.vue?vue&type=style&index=0&id=676b8fc4&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDrawer,QScrollArea,QToolbar,QBtn,QImg,QSeparator,QList,QItem,QItemSection,QIcon,QItemLabel,QExpansionItem,QBadge});qInstall(component, 'directives', {Ripple});
