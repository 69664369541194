export default [
  {
    key: 'analytics',
    title: 'Analytics',
    icon: 'img:icons/fluent/bar-chart.svg',
    permissions: ['role:admin', 'access:ma-events-table', 'access:analytics'],
    children: [
      {
        key: 'events',
        path: 'events',
        title: 'MA Metrics',
        icon: 'img:icons/fluent/heat-map.svg'
      },
      {
        key: 'funnels-status',
        path: 'funnels-status',
        title: 'Funnels Status',
        icon: 'img:icons/fluent/server.svg'
      },
      {
        key: 'funnels-metrics',
        path: 'funnels-metrics',
        title: 'Brand Funnels Metrics',
        icon: 'img:icons/fluent/bar-chart.svg'
      },
      {
        key: 'msb-monitoring',
        path: 'msb/monitoring',
        title: 'Home Sites Status',
        icon: 'img:/icons/fluent/server.svg'
      },
      {
        key: 'surfer-activity',
        path: 'surfer-activity',
        title: 'Surfer Activity',
        icon: 'img:/icons/line-chart.svg'
      },
      {
        key: 'ltv-reports',
        path: 'ltv-reports',
        title: 'LTV Reports',
        icon: 'img:/icons/line-chart.svg'
      }
    ]
  },
  {
    key: 'fraud',
    title: 'Fraud',
    icon: 'img:icons/warning-shield.svg',
    permissions: ['role:admin'],
    children: [
      {
        key: 'opti',
        path: 'fraud/opti/bin',
        title: 'Opti',
        icon: 'img:icons/setup.svg'
      },
      {
        key: 'opti2',
        path: 'fraud/opti2',
        title: 'Opti 2.0',
        icon: 'img:icons/setup.svg'
      }
    ]
  },
  {
    key: 'milkbox',
    title: 'MB Common Entities',
    icon: 'img:icons/milkbox-white-shaded.svg',
    path: 'milkbox',
    permissions: ['role:admin'],
    children: [
      {
        key: 'milkbox-brands',
        path: 'milkbox/brands',
        title: 'Brands',
        icon: 'img:icons/fluent/confectionery.svg'
      },
      {
        key: 'milkbox-accounts',
        path: 'milkbox/accounts',
        title: 'Corporations',
        icon: 'img:icons/fluent/organization.svg'
      },
      {
        key: 'milkbox-hostnames',
        path: 'milkbox/custom-hostnames',
        title: 'Custom Hostnames',
        icon: 'img:icons/fluent/dns.svg'
      },
      {
        key: 'milkbox-domains',
        path: 'milkbox/domains',
        title: 'Domains',
        icon: 'img:icons/fluent/website.svg'
      },
      {
        key: 'milkbox-industries',
        path: 'milkbox/industries',
        title: 'Industries',
        icon: 'img:icons/color/factory.svg'
      },
      {
        key: 'milkbox-members',
        path: 'milkbox/members',
        title: 'Members',
        icon: 'img:icons/fluent/people.svg'
      },
      {
        key: 'milkbox-offers',
        path: 'milkbox/offers',
        title: 'Offers',
        icon: 'img:icons/fluent/citrus.svg'
      },
      {
        key: 'milkbox-sweeps',
        path: 'milkbox/sweeps',
        title: 'Sweeps',
        icon: 'img:icons/fluent/gift.svg'
      },
      {
        key: 'milkbox-operators',
        path: 'milkbox/operators',
        title: 'Operators',
        icon: 'img:icons/fluent/administrator-male.svg'
      },
      {
        key: 'milkbox-subscriptions',
        path: 'milkbox/subscriptions',
        title: 'Subscriptions',
        icon: 'img:icons/fluent/general-ledger.svg'
      },
      {
        key: 'milkbox-subscription-plans',
        path: 'milkbox/subscription-plans',
        title: 'Subscription Plans',
        icon: 'mdi-receipt'
      },
      {
        key: 'milkbox-schemas',
        path: 'milkbox/schemas',
        title: 'Schemas',
        icon: 'img:icons/fluent/upload-link-document.svg'
      }
    ]
  },
  {
    key: 'memberships',
    title: 'Memberships Management',
    path: 'memberships',
    icon: 'img:icons/membership.png',
    permissions: ['role:admin'],
    children: [
      {
        key: 'milkbox-memberships',
        path: 'memberships/memberships',
        title: 'Memberships',
        icon: 'img:icons/membership.png'
      },
      {
        key: 'milkbox-plans',
        path: 'memberships/plans',
        title: 'Plans',
        icon: 'img:icons/planning.png'
      },
      {
        key: 'milkbox-prices',
        path: 'memberships/prices',
        title: 'Prices',
        icon: 'img:icons/color/coins.svg'
      },
      {
        key: 'milkbox-ppp-indexes',
        path: 'memberships/ppp-indexes',
        title: 'PPP indexes',
        icon: 'img:icons/line-chart.svg'
      }
    ]
  }
]
