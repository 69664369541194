import { make } from 'vuex-pathify'

const state = {
  env: {
    id: 'prod',
    title: 'Production',
    color: 'positive'
  },
  envs: [
    { id: 'local', title: 'Local Dev', color: 'blue-grey' },
    { id: 'dev', title: 'Dev', color: 'accent' },
    { id: 'staging', title: 'Staging', color: 'warning' },
    { id: 'prod', title: 'Production', color: 'positive' }
  ],
  search: '',
  globalSearchBarVisible: true,
  overlay: '',
  overlayOpts: {}
}

export default {
  namespaced: true,
  state,
  mutations: {
    ...make.mutations(state)
  },
  getters: {
    graphEndpoint: state => {
      switch (state.env.id) {
        case 'prod':
          return 'https://graph-api.bouba.cloud'
        case 'staging':
          return 'https://staging-graph-api.bouba.cloud'
        case 'local':
          return window.localStorage.getItem('graph_endpoint') || 'http://localhost:9000'
        default:
          return 'https://dev-graph-api.bouba.cloud'
      }
    },
    cdnEndpoint: state => {
      switch (state.env.id) {
        case 'prod':
          return 'https://cdn.bouba.cloud'
        case 'staging':
          return 'https://staging-cdn.bouba.cloud'
        case 'dev':
          return 'https://dev-cdn.bouba.cloud'
        default:
          return 'https://cdn.bouba.cloud'
      }
    }
  }
}
