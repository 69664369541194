export default [
  { path: 'mfb/funnel_pointers/:id?/:section?', component: () => import('./pages/FunnelPointers.vue') },
  { path: 'mfb/funnels/:id?/:section?/:sub_id?', component: () => import('./pages/Funnels.vue') },
  { path: 'mfb/recipes/:id?/:section?', component: () => import('./pages/Recipes.vue') },
  { path: 'mfb/templates/:id?/:section?', component: () => import('./pages/Templates.vue') },
  { path: 'mfb/template_blocks/:id?/:section?', component: () => import('./pages/TemplateBlocks.vue') },
  { path: 'mfb/device_notification_definitions/:id?/:section?', component: () => import('./pages/DeviceNotificationDefinitions.vue') },
  { path: 'mfb/device_notification_topics/:id?/:section?', component: () => import('./pages/DeviceNotificationTopics.vue') },
  { path: 'mfb/device_notification_tokens/:id?/:section?', component: () => import('./pages/DeviceNotificationTokens.vue') },
  { path: 'mfb/portal/:endpoint?', component: () => import('./pages/Portal.vue') }
]
