<template lang="pug">
  q-drawer(content-class='bg-secondary', show-if-above, side='left', v-model="drawer")
    q-scroll-area.sidenav(
      :thumb-style='thumbStyle'
      :bar-style='barStyle'
      )
      q-toolbar.sidenav-clocks.text-white.bg-blue-8.justify-around
        div
          strong MTL
          span {{timeMtl}}
        div
          strong UTC
          span {{timeUTC}}
        div
          strong MALTA
          span {{timeMalta}}
        div
          strong UKR
          span {{timeUkraine}}
        //- q-btn(
        //-   icon='img:icons/milkbox-white.svg'
        //-   style='opacity: .5;'
        //-   flat
        //-   padding='xs'
        //-   to='/'
        //-   )
      //- q-img(
      //-   src='../assets/hero/hero-rocketleap.jpg'
      //-   style='height: 150px'
      //-   transition='fade'
      //-   )
      //- q-separator(color='accent', style='height: 5px;')
      q-list(separator)
        q-item.text-white(
          clickable
          v-ripple
          to='/'
          exact
          active-class='bg-blue-7 text-white'
          )
          q-item-section(avatar)
            q-icon(name='img:icons/fluent/dashboard-layout.svg')
          q-item-section
            q-item-label Dashboard
        q-expansion-item(
          v-for='(menuItem, idx) of menu'
          :key='`menuitem-` + menuItem.key'
          group='leftNav'
          :icon='menuItem.icon'
          :label='menuItem.title'
          header-class='text-white'
          :default-opened='$route.path.startsWith(`/` + menuItem.path)'
          )
          template(v-if='menuItem.children.length > 0')
            .bg-grey-4
              q-list.bg-grey-1(separator)
                template(v-for='subMenuItem of menuItem.children')
                  q-item.text-primary(
                    v-if='subMenuItem.url'
                    :key='`submenuitem-` + subMenuItem.key'
                    clickable
                    v-ripple
                    @click='goToUrl(subMenuItem.url)'
                    )
                    q-item-section(avatar)
                      q-icon(:name='subMenuItem.icon')
                    q-item-section
                      q-item-label {{subMenuItem.title}}
                    q-item-section(side)
                      q-icon(name='las la-external-link-square-alt', size='xs')
                  q-item.text-primary(
                    v-else
                    :key='`submenuitem-` + subMenuItem.key'
                    clickable
                    v-ripple
                    :to='`/` + subMenuItem.path'
                    active-class='bg-blue-7 text-white'
                    :disable='subMenuItem.disabled'
                    )
                    q-item-section(avatar)
                      q-icon(:name='subMenuItem.icon')
                    q-item-section
                      q-item-label {{subMenuItem.title}}
                    q-item-section(v-if='subMenuItem.badge', side)
                      q-badge(color='blue')
                        span(style='font-size: 10px;') {{subMenuItem.badge}}
</template>

<script>
import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import { DateTime } from 'luxon'
import { sync } from 'vuex-pathify'

const moduleContext = require.context('../modules/', true, /nav\.js$/)
const navMenu = []
moduleContext.keys().forEach(m => {
  moduleContext(m).default.forEach(n => {
    const existing = find(navMenu, ['key', n.key])
    if (existing) {
      existing.children = sortBy([
        ...existing.children,
        ...n.children
      ], ['title'])
    } else {
      navMenu.push(n)
    }
  })
})

export default {
  props: {
    drawer: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      companies: [
        { id: 2, title: 'Milkbox' }
        // { id: 4, title: 'Grindstone Capital' }
      ],
      menu: sortBy(navMenu, ['title']),
      thumbStyle: {
        right: '1px',
        borderRadius: '5px',
        backgroundColor: '#000',
        width: '5px',
        opacity: 0.5
      },
      barStyle: {
        width: '7px'
      },
      timeMtl: DateTime.local().setZone('America/Toronto').toLocaleString(DateTime.TIME_SIMPLE),
      timeUTC: DateTime.local().setZone('Europe/London').toLocaleString(DateTime.TIME_SIMPLE),
      timeMalta: DateTime.local().setZone('Europe/Malta').toLocaleString(DateTime.TIME_SIMPLE),
      timeUkraine: DateTime.local().setZone('Europe/Kiev').toLocaleString(DateTime.TIME_SIMPLE)
    }
  },
  methods: {
    goToUrl (url) {
      window.open(url, '_blank')
    },
    filterMenuByUserPermissions () {
      const allowedMenus = this.menu.filter((menuItem) => {
        let isAllowed = false
        if (menuItem.permissions) {
          menuItem.permissions.forEach((menuPermission) => {
            if (this.userPermissionsMap[menuPermission]) {
              isAllowed = true
            }
          })
          return isAllowed
        } else {
          return true
        }
      })
      this.menu = sortBy(allowedMenus, ['title'])
    }
  },
  computed: {
    userPermissionsMap: sync('auth/userPermissionsMap')
  },
  mounted () {
    setInterval(() => {
      this.timeMtl = DateTime.local().setZone('America/Toronto').toLocaleString(DateTime.TIME_SIMPLE)
      this.timeUTC = DateTime.local().setZone('Europe/London').toLocaleString(DateTime.TIME_SIMPLE)
      this.timeMalta = DateTime.local().setZone('Europe/Malta').toLocaleString(DateTime.TIME_SIMPLE)
      this.timeUkraine = DateTime.local().setZone('Europe/Kiev').toLocaleString(DateTime.TIME_SIMPLE)
    }, 1000)
    this.filterMenuByUserPermissions()
  }
}
</script>

<style lang="scss">
.sidenav {
  height: 100%;

  &-clocks {
    > div {
      text-align: center;
      line-height: 13px;
    }

    strong {
      font-size: 10px;
      display: block;
      letter-spacing: .8px;
      color: rgba(255,255,255,.7)
    }

    span {
      font-size: 12px;
    }
  }
}
.sidenav-active {
  color: white;
  background: #F2C037;
}
</style>
