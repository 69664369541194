export default [
  {
    key: 'mfb',
    title: 'Funnel Builder',
    icon: 'img:icons/fluent/filter.svg',
    path: 'mfb',
    permissions: [
      'role:admin'
    ],
    children: [
      {
        key: 'mfb-funnel-pointers',
        path: 'mfb/funnel_pointers',
        title: 'Funnel Pointers',
        icon: 'img:icons/funnel_alias.svg'
      },
      {
        key: 'mfb-funnels',
        path: 'mfb/funnels',
        title: 'Funnels',
        icon: 'img:icons/funnel.svg'
      },
      {
        key: 'mfb-recipes',
        path: 'mfb/recipes',
        title: 'Recipes',
        icon: 'img:icons/recipes.svg'
      },
      {
        key: 'mfb-templates',
        path: 'mfb/templates',
        title: 'Templates',
        icon: 'img:icons/template.svg'
      },
      {
        key: 'mfb-template-blocks',
        path: 'mfb/template_blocks',
        title: 'Template Blocks',
        icon: 'img:icons/template-blocks.svg'
      },
      {
        key: 'mfb-device-notification-definitions',
        path: 'mfb/device_notification_definitions',
        title: 'Firebase Notification Definitions',
        icon: 'img:icons/device-notification-definition.svg'
      },
      {
        key: 'mfb-device-notification-topics',
        path: 'mfb/device_notification_topics',
        title: 'Firebase Notification Topics',
        icon: 'img:icons/device-notification-topics.svg'
      },
      {
        key: 'mfb-device-notification-tokens',
        path: 'mfb/device_notification_tokens',
        title: 'Firebase Notification Tokens',
        icon: 'img:icons/device-notification-tokens.svg'
      },
      {
        key: 'mfb-portal',
        path: 'mfb/portal',
        title: 'Portal',
        icon: 'img:icons/api.svg'
      }
    ]
  }
]
