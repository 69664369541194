export default [
  {
    key: 'docs',
    title: 'Documentation',
    icon: 'img:icons/fluent/books.svg',
    permissions: [
      'role:admin'
    ],
    children: [
      {
        key: 'docs-api',
        path: 'docs/api',
        title: 'API Docs',
        icon: 'img:icons/fluent/api.svg'
      }
    ]
  }
]
