export default [
  {
    key: 'dev',
    title: 'DevOps / Infra Tools',
    icon: 'img:icons/fluent/console.svg',
    permissions: [
      'role:admin'
    ],
    children: [
      {
        key: 'auth0',
        path: 'auth0',
        title: 'Auth0',
        icon: 'img:icons/auth0.svg',
        url: 'https://manage.auth0.com/'
      },
      {
        key: 'aws',
        path: 'aws',
        title: 'AWS Management Console',
        icon: 'img:icons/color/amazon-web-services.svg',
        url: 'https://console.aws.amazon.com/'
      },
      {
        key: 'azdevops',
        path: 'azdevops',
        title: 'Azure DevOps',
        icon: 'img:icons/vsts.svg',
        url: 'https://dev.azure.com/'
      },
      {
        key: 'azure',
        path: 'azure',
        title: 'Azure Portal',
        icon: 'img:icons/color/azure.svg',
        url: 'https://portal.azure.com/'
      },
      {
        key: 'cloudflare',
        path: 'cloudflare',
        title: 'Cloudflare',
        icon: 'img:icons/cloudflare.svg',
        url: 'https://dash.cloudflare.com/'
      },
      {
        key: 'datadog',
        path: 'datadog',
        title: 'Datadog',
        icon: 'img:icons/datadog.svg',
        url: 'https://app.datadoghq.com/dashboard/lists'
      },
      {
        key: 'do',
        path: 'do',
        title: 'DigitalOcean',
        icon: 'img:icons/digitalocean.svg',
        url: 'https://cloud.digitalocean.com/'
      },
      {
        key: 'gcp',
        path: 'gcp',
        title: 'Google Cloud Platform',
        icon: 'img:icons/color/google-cloud-platform.svg',
        url: 'https://console.cloud.google.com/'
      },
      {
        key: 'mongoatlas',
        path: 'mongoatlas',
        title: 'MongoDB Atlas',
        icon: 'img:icons/color/mongodb.svg',
        url: 'https://cloud.mongodb.com/'
      },
      {
        key: 'sentry',
        path: 'sentry',
        title: 'Sentry',
        icon: 'img:icons/sentry.svg',
        url: 'https://sentry.io/organizations/rocketleap/projects/'
      }
    ]
  },
  // {
  //   key: 'exps',
  //   title: 'Experiments',
  //   icon: 'las la-satellite',
  //   children: [
  //     {
  //       key: 'experiments',
  //       path: 'experiments',
  //       title: 'Experimentation Engine',
  //       icon: 'img:icons/color/firework.svg'
  //     }
  //   ]
  // },
  {
    key: 'localization',
    title: 'Localization',
    icon: 'img:icons/fluent/translation.svg',
    permissions: [
      'role:admin'
    ],
    children: [
      {
        key: 'lokalise',
        path: 'lokalise',
        title: 'Lokalise',
        icon: 'img:icons/lokalise.svg',
        url: 'https://app.lokalise.com/'
      }
    ]
  },
  {
    key: 'planning',
    title: 'Planning',
    icon: 'img:icons/fluent/timeline-week.svg',
    permissions: [
      'role:admin'
    ],
    children: [
      {
        key: 'airtable',
        path: 'airtable',
        title: 'Airtable',
        icon: 'img:icons/airtable.svg',
        url: 'https://airtable.com/'
      },
      {
        key: 'clickup',
        path: 'clickup',
        title: 'ClickUp',
        icon: 'img:icons/clickup.svg',
        url: 'https://app.clickup.com/'
      },
      // {
      //   key: 'glidr',
      //   path: 'glidr',
      //   title: 'Glidr',
      //   icon: 'img:icons/glidr.svg'
      // },
      {
        key: 'integromat',
        path: 'integromat',
        title: 'Integromat',
        icon: 'img:icons/integromat.svg',
        url: 'https://www.integromat.com/en/login'
      },
      {
        key: 'jira',
        path: 'jira',
        title: 'JIRA',
        icon: 'img:icons/jira.svg',
        url: 'https://rocketleap.atlassian.net/'
      },
      {
        key: 'confluence',
        path: 'confluence',
        title: 'Confluence',
        icon: 'img:icons/confluence.svg',
        url: 'https://rocketleap.atlassian.net/wiki'
      },
      {
        key: 'miro',
        path: 'miro',
        title: 'Miro',
        icon: 'img:icons/miro.svg',
        url: 'https://miro.com/app/dashboard/'
      },
      {
        key: 'figma',
        path: 'figma',
        title: 'Figma',
        icon: 'img:icons/figma.svg',
        url: 'https://airtable.com/login'
      }
    ]
  },
  // {
  //   key: 'support',
  //   title: 'Support',
  //   icon: 'img:icons/fluent/technical-support.svg',
  //   children: [
  //     {
  //       key: 'zendesk',
  //       path: 'zendesk',
  //       title: 'Zendesk',
  //       icon: 'img:icons/zendesk.svg',
  //       url: 'https://milkboxhelp.zendesk.com/agent/dashboard'
  //     }
  //   ]
  // },
  {
    key: 'mobile',
    title: 'Mobile Dev Tools',
    icon: 'img:icons/fluent/smartphone-tablet.svg',
    permissions: [
      'role:admin'
    ],
    children: [
      {
        key: 'firebase',
        path: 'firebase',
        title: 'Firebase',
        icon: 'img:icons/firebase.svg',
        url: 'https://console.firebase.google.com/'
      },
      {
        key: 'googlestore',
        path: 'googlestore',
        title: 'Google Store',
        icon: 'img:icons/googlestore.svg',
        url: 'https://play.google.com/apps/publish'
      },
      {
        key: 'applestore',
        path: 'applestore',
        title: 'Apple Store',
        icon: 'img:icons/appstore.svg',
        url: 'https://developer.apple.com/account/'
      }
    ]
  }
]
