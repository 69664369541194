<template lang="pug">
  codemirror.codemirror-vue-container(
    :value="code"
    :options="cmOptions"
    @input="onCmCodeChange"
  )
</template>

<script>
import { codemirror } from 'vue-codemirror'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'

export default {
  components: {
    codemirror
  },
  props: {
    mode: {
      type: String,
      default: 'text/javascript'
    },
    tabSize: {
      type: String,
      default: '2'
    },
    value: {
      type: [String, Object],
      default: () => ({})
    },
    schema: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      code: this.value,
      cmOptions: {
        tabSize: this.tabSize,
        mode: this.mode,
        theme: 'material',
        lineNumbers: true,
        line: true
        // more CodeMirror options...
      }
    }
  },
  methods: {
    onCmCodeChange (code) {
      this.$emit('input', code)
    }
  }
}
</script>

<style lang="scss">
  .CodeMirror {
    border: none;
    height: 700px;
  }
</style>
