export default [
  { name: 'events', path: 'events/:metric?/:id?', component: () => import('./pages/Events.vue') },
  { name: 'funnels-status', path: 'funnels-status', component: () => import('./pages/FunnelsStatus.vue') },
  { name: 'funnels-metrics', path: 'funnels-metrics', component: () => import('./pages/FunnelsMetrics.vue') },
  { name: 'surfer-activity', path: 'surfer-activity', component: () => import('./pages/SurferActivity.vue') },
  { name: 'ltv-reports', path: 'ltv-reports', component: () => import('./pages/LtvReports.vue') },
  { name: 'milkbox-accounts', path: 'milkbox/accounts/:id?/:section?', component: () => import('./pages/Accounts.vue') },
  { name: 'milkbox-brands', path: 'milkbox/brands/:id?/:section?', component: () => import('./pages/Brands.vue') },
  { name: 'milkbox-custom-hostnames', path: 'milkbox/custom-hostnames/:id?/:section?', component: () => import('./pages/CustomHostnames.vue') },
  { name: 'milkbox-domains', path: 'milkbox/domains/:id?/:section?', component: () => import('./pages/Domains.vue') },
  { name: 'milkbox-operators', path: 'milkbox/operators/:id?/:section?', component: () => import('./pages/Operators.vue') },
  { name: 'milkbox-industries', path: 'milkbox/industries/:id?/:section?', component: () => import('./pages/Industries.vue') },
  { name: 'milkbox-membersips', path: 'memberships/memberships/:id?/:section?', component: () => import('./pages/Memberships.vue') },
  { name: 'milkbox-plans', path: 'memberships/plans/:id?/:section?', component: () => import('./pages/Plans.vue') },
  { name: 'milkbox-ppp-indexes', path: 'memberships/ppp-indexes/:id?/:section?', component: () => import('./pages/PppIndexes.vue') },
  { name: 'milkbox-prices', path: 'memberships/prices/:id?/:section?', component: () => import('./pages/Prices.vue') },
  { name: 'milkbox-members', path: 'milkbox/members/:id?/:section?', component: () => import('./pages/Members.vue') },
  { name: 'milkbox-offers', path: 'milkbox/offers/:id?/:section?', component: () => import('./pages/Offers.vue') },
  { name: 'milkbox-sweeps', path: 'milkbox/sweeps/:sweep_tag?/:section?', component: () => import('./pages/Sweeps.vue') },
  { name: 'milkbox-subscriptions', path: 'milkbox/subscriptions/:id?/:section?', component: () => import('./pages/Subscriptions.vue') },
  { name: 'milkbox-subscription-plans', path: 'milkbox/subscription-plans/:id?/:section?', component: () => import('./pages/SubscriptionPlans.vue') },
  { name: 'milkbox-schemas', path: 'milkbox/schemas/:schema?', component: () => import('./pages/Schemas.vue') }
]
