export default [
  {
    key: 'giveaway',
    title: 'Giveaway sites',
    icon: 'img:icons/fluent/gift.svg',
    permissions: [
      'role:admin'
    ],
    children: [
      {
        key: 'giveaway-brands',
        path: 'giveaway/brands',
        title: 'Brands',
        icon: 'img:icons/fluent/confectionery.svg'
      },
      {
        key: 'giveaway-members',
        path: 'giveaway/members',
        title: 'Members',
        icon: 'img:icons/fluent/people.svg'
      },
      {
        key: 'giveaway-campaigns',
        path: 'giveaway/campaigns',
        title: 'Campaigns',
        icon: 'img:icons/fluent/donate.svg'
      }
    ]
  }
]
