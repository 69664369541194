export default [
  {
    key: 'paymentsmb',
    title: 'Payments MB',
    icon: 'img:icons/fluent/bar-chart.svg',
    permissions: ['role:paymentsmb'],
    children: [
      {
        key: 'paymentsmb-funnels-metrics',
        path: 'funnels-metrics',
        title: 'Brand Funnels Metrics',
        icon: 'img:icons/fluent/bar-chart.svg'
      }
    ]
  }
]
