export default [
  {
    key: 'mfbseg',
    title: 'Segmentation / Experiments',
    icon: 'img:icons/fluent/slice.svg',
    path: 'mfbseg',
    permissions: [
      'role:admin',
      'role:segments'
    ],
    children: [
      {
        key: 'mfbseg-segmentation-segments',
        path: 'mfbseg/segments',
        title: 'Segments',
        icon: 'img:icons/pie-chart.svg'
      },
      {
        key: 'mfbseg-segmentation-segmentrules',
        path: 'mfbseg/segmentRules',
        title: 'Segment Rules',
        icon: 'img:icons/test.svg'
      },
      {
        key: 'mfbseg-segmentation-dimensions',
        path: 'mfbseg/dimensions',
        title: 'Dimensions',
        icon: 'img:icons/line-chart.svg'
      },
      {
        key: 'mfbseg-segmentation-userproperties',
        path: 'mfbseg/userProperties',
        title: 'User Properties',
        icon: 'img:icons/setup.svg'
      },
      {
        key: 'mfbseg-segmentation-membersessions',
        path: 'mfbseg/memberSessions',
        title: 'Member Sessions',
        icon: 'img:icons/id-card.svg'
      },
      {
        key: 'mfbseg-segmentation-usersessions',
        path: 'mfbseg/userSessions',
        title: 'User Sessions',
        icon: 'img:icons/user.svg'
      },
      {
        key: 'mfbseg-segmentation-userstates',
        path: 'mfbseg/userStates',
        title: 'User States',
        icon: 'img:icons/statistics.svg'
      },
      {
        key: 'mfbseg-segmentation-settings',
        path: 'mfbseg/segmentationSettings',
        title: 'Segmentation Settings',
        icon: 'img:icons/segmentation-settings.svg'
      }
    ]
  }
]
