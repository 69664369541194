import createAuth0Client from '@auth0/auth0-spa-js'
import { make } from 'vuex-pathify'

const state = {
  domain: 'milkbox.auth0.com',
  clientId: 'frzb3xUe48MJtMbzKyDOa0rpUI9dtjS8',
  audience: 'https://api',
  redirectUri: `${window.location.origin}/authenticate`,
  loading: true,
  isAuthenticated: false,
  user: {},
  userPermissionsMap: {},
  popupOpen: false,
  error: null,
  routeRedirect: '/',
  chartsToken: ''
}

window.auth0Client = null

const makeUserPermissionsMap = async () => {
  const userPermissionsMap = {}

  try {
    const token = await window.auth0Client.getTokenSilently()
    if (token) {
      const decoded = JSON.parse(window.atob(token.split('.')[1]))
      // Map user permissions for faster access later
      decoded.permissions.forEach((userPermission) => {
        userPermissionsMap[userPermission] = userPermission
      })
    }
  } catch (err) {
    // Return empty permissions map on "get token" error
    console.log(err)
  }

  return userPermissionsMap
}

export default {
  namespaced: true,
  state,
  mutations: {
    ...make.mutations(state)
  },
  actions: {
    /** Authenticates the user using a popup window */
    async loginWithPopup ({ commit }, o) {
      commit('SET_POPUP_OPEN', true)

      try {
        await window.auth0Client.loginWithPopup(o)
      } catch (e) {
        // eslint-disable-next-line
        console.error(e);
      } finally {
        commit('SET_POPUP_OPEN', false)
      }

      commit('SET_USER', await window.auth0Client.getUser())
      commit('SET_USER_PERMISSIONS_MAP', await makeUserPermissionsMap())
      commit('SET_IS_AUTHENTICATED', true)
      console.log('!!!!!!loginWithPopup!!!!!')
    },
    /** Handles the callback when logging in using a redirect */
    async handleRedirectCallback ({ commit }) {
      commit('SET_LOADING', true)
      try {
        await window.auth0Client.handleRedirectCallback()
        commit('SET_USER', await window.auth0Client.getUser())
        commit('SET_USER_PERMISSIONS_MAP', await makeUserPermissionsMap())
        commit('SET_IS_AUTHENTICATED', true)
      } catch (e) {
        commit('SET_ERROR', e)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    /** Authenticates the user using the redirect method */
    loginWithRedirect ({ state }, o) {
      return window.auth0Client.loginWithRedirect(o)
    },
    /** Returns all the claims present in the ID token */
    getIdTokenClaims ({ state }, o) {
      return window.auth0Client.getIdTokenClaims(o)
    },
    /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
    getTokenSilently ({ state }, o) {
      return window.auth0Client.getTokenSilently(o)
    },
    /** Gets the access token using a popup window */

    getTokenWithPopup ({ state }, o) {
      return window.auth0Client.getTokenWithPopup(o)
    },
    /** Logs the user out and removes their session on the authorization server */
    logout (o) {
      return window.auth0Client.logout(o)
    },
    async initialize ({ state, commit }, clb) {
      // Create a new instance of the SDK client using members of the given options object
      window.auth0Client = await createAuth0Client({
        domain: state.domain,
        client_id: state.clientId,
        audience: state.audience,
        redirect_uri: state.redirectUri
      })

      const urlParams = new URLSearchParams(window.location.search)

      try {
        // If the user is returning to the app after authentication..
        if (urlParams.has('code') && urlParams.has('state')) {
          // handle the redirect and retrieve tokens
          await window.auth0Client.handleRedirectCallback()
          // const { appState } = await window.auth0Client.handleRedirectCallback()

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          // clb(appState)
        } else if (urlParams.has('error') && urlParams.has('error_description')) {
          throw new Error(urlParams.get('error_description'))
        }
      } catch (e) {
        commit('SET_ERROR', e)
      } finally {
        // Initialize our internal authentication state
        commit('SET_IS_AUTHENTICATED', await window.auth0Client.isAuthenticated())
        commit('SET_USER', await window.auth0Client.getUser())
        commit('SET_USER_PERMISSIONS_MAP', await makeUserPermissionsMap())
        commit('SET_LOADING', false)

        clb()
      }
    }
  }
}
