import nth from 'lodash/nth'
import { toPascalCase } from 'js-convert-case'

import Sidenav from '../components/Sidenav.vue'
import JsonEditor from '../components/JsonEditor.vue'
import CodeMirror from '../components/CodeMirror.vue'

export default async ({ Vue }) => {
  Vue.component('Sidenav', Sidenav)
  Vue.component('JsonEditor', JsonEditor)
  Vue.component('CodeMirror', CodeMirror)

  const widgetContext = require.context('../modules/', true, /widgets\/[a-zA-Z0-9]+\.vue$/, 'lazy')
  widgetContext.keys().forEach(m => {
    const fParts = m.split('/')
    const widgetName = toPascalCase(nth(fParts, -3)) + toPascalCase(nth(fParts, -1).split('.')[0]) + 'Widget'
    Vue.component(widgetName, () => widgetContext(m))
  })

  const overlayContext = require.context('../modules/', true, /overlays\/[a-zA-Z0-9]+\.vue$/, 'lazy')
  overlayContext.keys().forEach(m => {
    const fParts = m.split('/')
    const overlayName = toPascalCase(nth(fParts, -3)) + toPascalCase(nth(fParts, -1).split('.')[0]) + 'Overlay'
    Vue.component(overlayName, () => overlayContext(m))
  })

  const dialogContext = require.context('../modules/', true, /dialogs\/[a-zA-Z0-9]+\.vue$/, 'lazy')
  dialogContext.keys().forEach(m => {
    const fParts = m.split('/')
    const dialogName = toPascalCase(nth(fParts, -3)) + toPascalCase(nth(fParts, -1).split('.')[0]) + 'Dialog'
    Vue.component(dialogName, () => dialogContext(m))
  })

  const componentContext = require.context('../modules/', true, /components\/[a-zA-Z0-9]+\.vue$/, 'lazy')
  componentContext.keys().forEach(m => {
    const fParts = m.split('/')
    const componentName = toPascalCase(nth(fParts, -3)) + toPascalCase(nth(fParts, -1).split('.')[0])
    Vue.component(componentName, () => componentContext(m))
  })
}
