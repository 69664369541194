import axios from 'axios'

const state = {
  api: {
    endpoints: {
      local: 'https://6h8pxwrdqi.execute-api.us-east-1.amazonaws.com/staging/api/v1',
      dev: 'https://6h8pxwrdqi.execute-api.us-east-1.amazonaws.com/staging/api/v1',
      staging: 'https://6h8pxwrdqi.execute-api.us-east-1.amazonaws.com/staging/api/v1',
      prod: 'https://gsjp5oiw8l.execute-api.us-east-1.amazonaws.com/production/api/v1'
    }
  }
}

/**
 * @typedef {object} BinWeight
 * @property {number} bin
 * @property {number} payout_weight
 * @property {number} created_at
 * @property {string} created_by
 * @property {number} previous_weight
 * @property {number} bin_profile_id
 */

/**
 * @typedef {object} Affiliate
 * @property {number} ap
 * @property {number} affiliate_id
 * @property {string} affiliate_name
 */

/**
 * @typedef {object} AffiliateWeight
 * @property {number} ap
 * @property {number} affiliate_id
 * @property {string} affiliate_name
 * @property {number} weight
 * @property {number} previous_weight
 * @property {string} country_code
 * @property {number} created_at
 * @property {string} created_by
 */

export default {
  namespaced: true,
  state,
  actions: {
    async getBinWeights ({ commit, state, rootState }, { limit = 10, offset = 0, bin = null, sort = [['created_at', 'DESC']] }) {
      const accessToken = await window.auth0Client.getTokenSilently()
      const env = rootState.site.env.id
      const endpoint = state.api.endpoints[env]

      const url = new URL(`${endpoint}/bin`)

      url.searchParams.append('limit', limit)
      url.searchParams.append('offset', offset)
      url.searchParams.append('sort', JSON.stringify(sort))

      if (bin) {
        url.searchParams.append('bin', bin)
      }

      const request = {
        method: 'GET',
        url: url.toString(),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      }

      return axios(request).then(response => {
        return {
          data: /** @type {BinWeight[]} **/ (response.data.data),
          total: response.data.meta.count
        }
      })
    },

    async getAffiliateWeights (
      { commit, state, rootState },
      {
        limit = 10, offset = 0, sort = [['created_at', 'DESC']],
        geo = null,
        network = null,
        affiliate = null
      }
    ) {
      const accessToken = await window.auth0Client.getTokenSilently()
      const env = rootState.site.env.id
      const endpoint = state.api.endpoints[env]

      const url = new URL(`${endpoint}/affiliate_country`)

      url.searchParams.append('limit', limit)
      url.searchParams.append('offset', offset)
      url.searchParams.append('sort', JSON.stringify(sort))

      if (geo) {
        url.searchParams.append('country_code', geo)
      }

      if (network) {
        url.searchParams.append('ap', network)
      }

      if (affiliate) {
        url.searchParams.append('affiliate_id', affiliate)
      }

      const { data: res } = await axios.get(url.toString(), {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })

      return {
        data: /** @type {AffiliateWeight[]} */ (res.data),
        total: res.meta.count
      }
    },

    /**
     * Update weights for bin
     *
     * @param {any} store
     *
     * @param {object} data
     * @param {BinWeight[]} data.bins
     * @param {string} data.author
     * @param {number} data.weight
     */
    async updateBinWeights ({ commit, state, rootState }, { bins, author, weight }) {
      const accessToken = await window.auth0Client.getTokenSilently()
      const env = rootState.site.env.id
      const endpoint = state.api.endpoints[env]

      const body = {
        submitter: author,
        data: bins.map((bin) => ({
          bin: bin.bin,
          bin_profile_id: bin.bin_profile_id,
          weight
        }))
      }

      await axios.post(`${endpoint}/bin/bulk`, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
    },

    /**
     * Get list of affiliates
     *
     * @param {any} store
     */
    async getAffiliates ({ commit, state, rootState }) {
      const accessToken = await window.auth0Client.getTokenSilently()
      const env = rootState.site.env.id
      const endpoint = state.api.endpoints[env]

      const { data } = await axios.get(`${endpoint}/affiliate_list`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })

      const affiliates = /** @type {Affiliate[]} */ (data.data)

      return affiliates.filter(a => a.affiliate_name !== null && a.affiliate_name !== '')
    },

    /**
     * Update weights for affiliate and country
     *
     * @param {any} store
     *
     * @param {object} data
     * @param {AffiliateWeight[]} data.affiliateWeights
     * @param {string} data.author
     * @param {number} data.weight
     */
    async updateAffiliateWeights ({ commit, state, rootState }, { affiliateWeights, author, weight }) {
      const accessToken = await window.auth0Client.getTokenSilently()
      const env = rootState.site.env.id
      const endpoint = state.api.endpoints[env]

      const body = {
        submitter: author,
        data: affiliateWeights.map((af) => ({
          ap: af.ap,
          affiliate_id: af.affiliate_id,
          affiliate_name: af.affiliate_name,
          country_code: af.country_code,
          weight
        }))
      }

      await axios.post(`${endpoint}/affiliate_country/bulk`, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
    },

    getEndpoint ({ state, rootState }) {
      const env = rootState.site.env.id
      return state.api.endpoints[env]
    }
  }
}
