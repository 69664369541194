import axios from 'axios'

const state = {
  api: {
    endpoints: {
      local: 'http://localhost:3000',
      dev: 'http://localhost:3000',
      staging: 'https://y8w3ofktkb.execute-api.us-east-1.amazonaws.com/Prod',
      prod: 'https://9w03fniica.execute-api.us-east-1.amazonaws.com/Prod'
    }
  },
  server: {
    endpoints: {
      local: 'http://localhost:3000',
      dev: 'http://localhost:3000',
      staging: 'https://staging-finance-api.finance-automation.services',
      prod: 'https://prod-finance-api.finance-automation.services'
    }
  }
}

export default {
  namespaced: true,
  state,
  actions: {
    async getXeroTenants ({ commit, state, rootState }, { corpsOnly = true }) {
      try {
        const accessToken = await window.auth0Client.getTokenSilently()
        const env = rootState.site.env.id
        const endpoint = state.api.endpoints[env]

        const query = '?corpsOnly=' + corpsOnly

        const request = {
          method: 'GET',
          url: `${endpoint}/xero/tenants${query}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }

        return axios(request).then(response => response.data)
      } catch (err) {
        console.log('EE', err)
      }
    },

    async vatAddRate ({ commit, state, rootState }, { country, rateName, rate, validFrom, validTo }) {
      const accessToken = await window.auth0Client.getTokenSilently()
      const env = rootState.site.env.id
      const endpoint = state.api.endpoints[env]

      const body = {
        country,
        rate_name: rateName,
        rate,
        from_date: validFrom
      }
      if (validTo) {
        body.to_date = validTo
      }

      const request = {
        method: 'POST',
        url: `${endpoint}/vat/add-vat-rate`,
        data: body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      }

      return axios(request).then(response => response.data)
    },

    async getVatRates ({ commit, state, rootState }, { country, rateName }) {
      const accessToken = await window.auth0Client.getTokenSilently()
      const env = rootState.site.env.id
      const endpoint = state.api.endpoints[env]

      const qParams = []
      if (country && country.length > 0) qParams.push('country=' + country)
      if (rateName && rateName.length > 0) qParams.push('rate_name=' + rateName)
      let query = ''
      if (qParams.length > 0) {
        query = '?' + qParams.join('&')
      }

      const request = {
        method: 'GET',
        url: `${endpoint}/vat/vat-rates${query}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      }

      return axios(request).then(response => response.data)
    },

    async expireVatRate ({ commit, state, rootState }, { country, rateName, expiryDate }) {
      const accessToken = await window.auth0Client.getTokenSilently()
      const env = rootState.site.env.id
      const endpoint = state.api.endpoints[env]

      const request = {
        method: 'POST',
        url: `${endpoint}/vat/expire-vat-rate`,
        data: {
          country,
          rate_name: rateName,
          expiry_date: expiryDate
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      }

      return axios(request).then(response => response.data)
    },

    async getMediaVatRates ({ commit, state, rootState }, { country, mediaType }) {
      const accessToken = await window.auth0Client.getTokenSilently()
      const env = rootState.site.env.id
      const endpoint = state.api.endpoints[env]

      const qParams = []
      if (country && country.length > 0) qParams.push('country=' + country)
      if (mediaType && mediaType.length > 0) qParams.push('media_type=' + mediaType)
      let query = ''
      if (qParams.length > 0) {
        query = '?' + qParams.join('&')
      }

      const request = {
        method: 'GET',
        url: `${endpoint}/vat/media-vat-rates${query}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      }

      return axios(request).then(response => response.data)
    },

    async addMediaVatRate ({ commit, state, rootState }, { country, mediaType, rateName }) {
      const accessToken = await window.auth0Client.getTokenSilently()
      const env = rootState.site.env.id
      const endpoint = state.api.endpoints[env]

      const body = {
        country,
        media_type: mediaType
      }
      if (rateName !== 'none') {
        body.rate_name = rateName
      }

      const request = {
        method: 'POST',
        url: `${endpoint}/vat/add-media-vat-rate`,
        data: body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      }

      return axios(request).then(response => response.data)
    },

    async updateMediaVatRate ({ commit, state, rootState }, { country, mediaType, newRateName }) {
      const accessToken = await window.auth0Client.getTokenSilently()
      const env = rootState.site.env.id
      const endpoint = state.api.endpoints[env]

      const body = {
        country,
        media_type: mediaType
      }
      if (newRateName !== 'none') {
        body.rate_name = newRateName
      }

      const request = {
        method: 'POST',
        url: `${endpoint}/vat/update-media-vat-rate`,
        data: body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      }

      return axios(request).then(response => response.data)
    },

    async getTaxAccrual ({ commit, state, rootState }, { tenantIDs, fromDate, toDate }) {
      const accessToken = await window.auth0Client.getTokenSilently()
      const env = rootState.site.env.id
      const endpoint = state.api.endpoints[env]

      const promises = []
      tenantIDs.forEach(tenantID => {
        promises.push(getTaxAccrualFor1Corp(endpoint, accessToken, tenantID, fromDate, toDate))
      })
      return await Promise.all(promises)
    }
  }
}

async function getTaxAccrualFor1Corp (endpoint, accessToken, tenantID, fromDate, toDate) {
  let query = '?tenantID=' + tenantID
  if (fromDate) query += '&fromDate=' + fromDate
  if (toDate) query += '&toDate=' + toDate

  const request = {
    method: 'GET',
    url: `${endpoint}/xero/tax-accrual-report${query}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  }

  return axios(request).then(response => response.data)
}
