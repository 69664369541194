export default [
  {
    key: 'datateam',
    title: 'Data Team',
    icon: 'img:icons/fluent/bar-chart.svg',
    permissions: ['role:admin'],
    children: [
      {
        key: 'datateam-reconciliation-monitor',
        path: 'datateam/reconciliation-monitor',
        title: 'Reconciliation Monitor',
        icon: 'img:/icons/line-chart.svg'
      }
    ]
  }
]
