export default [
  { path: 'mfbseg/segments/:id?/:section?', component: () => import('./pages/Segments.vue') },
  { path: 'mfbseg/segmentRules/:id?/:section?', component: () => import('./pages/SegmentRules.vue') },
  { path: 'mfbseg/dimensions/:id?/:section?', component: () => import('./pages/Dimensions.vue') },
  { path: 'mfbseg/userProperties/:id?/:section?', component: () => import('./pages/UserProperties.vue') },
  { path: 'mfbseg/userStates/:id?/:section?', component: () => import('./pages/UserStates.vue') },
  { path: 'mfbseg/userSessions/:id?/:section?', component: () => import('./pages/UserSessions.vue') },
  { path: 'mfbseg/memberSessions/:id?/:section?', component: () => import('./pages/MemberSessions.vue') },
  { path: 'mfbseg/segmentationSettings/:id?/:section?', component: () => import('./pages/SegmentationSettings.vue') }
]
