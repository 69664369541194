import { make } from 'vuex-pathify'

const state = {
  mediaTypes: [
    { code: 'audiobook', name: 'Audiobook', icon: 'las la-microphone', color: 'teal' },
    { code: 'ebook', name: 'Book', icon: 'las la-book', color: 'green' },
    { code: 'game', name: 'Game', icon: 'las la-gamepad', color: 'orange' },
    { code: 'movie', name: 'Movie', icon: 'lab la-youtube', color: 'pink' },
    { code: 'music', name: 'Music', icon: 'las la-record-vinyl', color: 'indigo' },
    { code: 'software', name: 'Software', icon: 'las la-table', color: 'yellow' }
  ]
}

export default {
  namespaced: true,
  state,
  mutations: {
    ...make.mutations(state)
  }
}
