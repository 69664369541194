export default async ({ app, router, store, Vue }) => {
  store.dispatch('auth/initialize', clb => {
    store.dispatch('data/fetchSchemas')
  })

  router.beforeEach((to, from, next) => {
    if (to.path === '/authenticate') {
      next()
    } else if (!to.meta.public && !store.get('auth/isAuthenticated')) {
      if (store.get('auth/loading')) {
        store.set('auth/routeRedirect', to.fullPath)
        router.replace('/authenticate')
      } else {
        next('/login')
      }
    } else {
      next()
    }
  })
}
