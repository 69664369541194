<template lang="pug">
  .jsoneditor-vue-container(ref='container')
</template>

<script>
import JSONEditor from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.css'
import Ajv from 'ajv'
import AjvBsonType from 'ajv-bsontype'
import isPlainObject from 'lodash/isPlainObject'

function findAndReplaceRecursively (target, propKey, find, replaceWith, curKey) {
  if (!isPlainObject(target)) {
    if (propKey === curKey && target === find) return replaceWith
    return target
  }
  return Object.keys(target)
    .reduce((carry, key) => {
      const val = target[key]
      carry[key] = findAndReplaceRecursively(val, propKey, find, replaceWith, key)
      return carry
    }, {})
}

export default {
  props: {
    mode: {
      type: String,
      default: 'tree'
    },
    value: {
      type: [Object, Array],
      default: () => ({}),
      required: true
    },
    schema: {
      type: Object,
      default: () => ({})
    },
    allowStringDates: {
      type: Boolean,
      default: false
    },
    statusBar: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      editor: null
    }
  },
  methods: {
    updateValue (newValue) {
      this.editor.set(newValue)
    }
  },
  mounted () {
    const ajv = new Ajv({
      allowUnionTypes: true
    })
    AjvBsonType(ajv)

    let schema = this.schema

    if (this.allowStringDates) {
      schema = findAndReplaceRecursively(schema, 'bsonType', 'date', ['date', 'string'])
    }

    this.editor = new JSONEditor(this.$refs.container, {
      mode: this.mode,
      mainMenuBar: false,
      navigationBar: false,
      schema: schema,
      ajv: ajv,
      statusBar: this.statusBar,
      onChange: () => {
        try {
          this.$emit('input', this.editor.get())
        } catch (err) {}
      }
    })
    this.editor.set(this.value)
  },
  beforeDestroy () {
    if (this.editor) {
      this.editor.destroy()
    }
  }
}
</script>

<style lang="scss">
  .jsoneditor-vue-container {
    .jsoneditor {
      border: none;
    }
  }
</style>
