const moduleContext = require.context('../modules/', true, /routes\.js$/)
const moduleRoutes = moduleContext.keys().reduce((acc, m) => {
  if (acc) {
    return [
      ...acc,
      ...moduleContext(m).default
    ]
  } else {
    return moduleContext(m).default
  }
}, [])

const routes = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: moduleRoutes
  },
  {
    path: '/authenticate',
    component: () => import('layouts/EmptyLayout.vue'),
    children: [
      { path: '', component: () => import('pages/Authenticate.vue') }
    ],
    meta: {
      public: true
    }
  },
  {
    path: '/login',
    component: () => import('layouts/EmptyLayout.vue'),
    children: [
      { path: '', component: () => import('pages/Login.vue'), meta: { public: true } }
    ]
  },
  {
    path: '/logout',
    component: () => import('layouts/EmptyLayout.vue'),
    children: [
      { path: '', component: () => import('pages/Logout.vue'), meta: { public: true } }
    ]
  },
  {
    path: '/log',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/Log.vue') }
    ]
  },
  {
    path: '/users',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/Users.vue') }
    ]
  }
]

// Always leave this as last one
routes.push({
  path: '*',
  component: () => import('pages/Error404.vue')
})

export default routes
