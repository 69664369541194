export default [
  {
    key: 'mpb',
    title: 'Product Builder',
    icon: 'img:icons/fluent/module.svg',
    path: 'mpb',
    permissions: [
      'role:admin'
    ],
    children: [
      {
        key: 'mpb-infrastructure',
        path: 'mpb/infrastructure',
        title: 'Infrastructure',
        icon: 'img:icons/fluent/server.svg'
      },
      {
        key: 'mpb-restplayground',
        path: 'mpb/restplayground',
        title: 'REST Playground',
        icon: 'img:icons/fluent/console.svg'
      },
      {
        key: 'mpb-streamingmedia',
        path: 'mpb/streamingmedia',
        title: 'Streaming Media',
        icon: 'img:icons/fluent/musical-notes.svg',
        badge: 'CMS'
      },
      {
        key: 'mpb-ecommerce',
        path: 'mpb/ecommerce',
        title: 'Ecommerce',
        icon: 'img:icons/fluent/shopping-cart.svg',
        badge: 'CMS'
      },
      {
        key: 'mpb-poolrestrictions',
        path: 'mpb/poolrestrictions',
        title: 'Pool Restrictions',
        icon: 'img:icons/fluent/close-octagon-outline.svg'
      },
      {
        key: 'mpb-ugc',
        path: 'mpb/ugc',
        title: 'User Generated Content',
        icon: 'img:icons/fluent/selfie.svg',
        badge: 'CMS',
        disabled: true
      },
      {
        key: 'mpb-vpn',
        path: 'mpb/vpn',
        title: 'VPN',
        icon: 'img:icons/fluent/security-wi-fi.svg',
        badge: 'CMS'
      },
      {
        key: 'mpb-monitoring',
        path: 'mpb/monitoring',
        title: 'Health Status',
        icon: 'img:/icons/fluent/server.svg'
      }
    ]
  }
]
