export default [
  {
    key: 'billing',
    title: 'Billing',
    icon: 'img:icons/fluent/money.svg',
    permissions: [
      'role:admin'
    ],
    children: [
      // {
      //   key: 'billing-paypal',
      //   path: 'paypal',
      //   title: 'Paypal',
      //   icon: 'img:icons/paypal.svg'
      // },
      // {
      //   key: 'billing-stripe',
      //   path: 'stripe',
      //   title: 'Stripe',
      //   icon: 'img:icons/color/stripe.svg'
      // },
      {
        key: 'billing-xero_dashboard',
        title: 'Xero Dashboard',
        icon: 'img:icons/xero.svg',
        url: 'https://go.xero.com/Dashboard/'
      },
      {
        key: 'billing-invoices',
        path: 'billing/invoices',
        title: 'Invoices',
        icon: 'img:icons/xero-invoices.svg'
      }
    ]
  }
]
