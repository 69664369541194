import axios from 'axios'

const state = {
  api: {
    endpoints: {
      local: 'https://staging-pricing-api.hyunaop.systems/api/v1',
      dev: 'https://staging-pricing-api.hyunaop.systems/api/v1',
      staging: 'https://staging-pricing-api.hyunaop.systems/api/v1',
      prod: 'https://pricing-api.hyunaop.systems/api/v1'
    }
  }
}

export default {
  namespaced: true,
  state,
  actions: {
    async getPricelists ({ commit, state, rootState }, { q = null }) {
      const accessToken = await window.auth0Client.getTokenSilently()
      const env = rootState.site.env.id
      const endpoint = state.api.endpoints[env]

      const qParams = []
      let query = ''
      if (qParams.length > 0) {
        query = '?' + qParams.join('&')
      }

      const request = {
        method: 'GET',
        url: `${endpoint}/pricelists${query}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      }

      return axios(request).then(response => response.data.data)
    },

    async getPricelistsAsExcel ({ commit, state, rootState }, { q = null }) {
      const accessToken = await window.auth0Client.getTokenSilently()
      const env = rootState.site.env.id
      const endpoint = state.api.endpoints[env]

      const qParams = []
      let query = ''
      if (qParams.length > 0) {
        query = '?' + qParams.join('&')
      }

      const request = {
        method: 'GET',
        url: `${endpoint}/pricelists.xlsx${query}`,
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          Authorization: `Bearer ${accessToken}`
        }
      }

      return axios(request).then(response => response.data)
    },

    async getOffers ({ commit, state, rootState }, { limit = 10, offset = 0, sortBy = null, sortDirection = 'asc', q = null }) {
      const accessToken = await window.auth0Client.getTokenSilently()
      const env = rootState.site.env.id
      const endpoint = state.api.endpoints[env]

      const qParams = []
      // if (country && country.length > 0) qParams.push('country=' + country)
      qParams.push('limit=' + limit)
      qParams.push('offset=' + offset)
      if (sortBy) {
        qParams.push(`order=[["${sortBy}", "${sortDirection}"]]`)
      }
      let query = ''
      if (qParams.length > 0) {
        query = '?' + qParams.join('&')
      }

      const request = {
        method: 'GET',
        url: `${endpoint}/offers${query}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      }

      return axios(request).then(response => { return { data: response.data.data, total: response.headers['x-total-count'] } })
    },

    async getOffersAsExcel ({ commit, state, rootState }, { q = null }) {
      const accessToken = await window.auth0Client.getTokenSilently()
      const env = rootState.site.env.id
      const endpoint = state.api.endpoints[env]

      const qParams = []
      let query = ''
      if (qParams.length > 0) {
        query = '?' + qParams.join('&')
      }

      const request = {
        method: 'GET',
        url: `${endpoint}/offers.xlsx${query}`,
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          Authorization: `Bearer ${accessToken}`
        }
      }

      return axios(request).then(response => response.data)
    },

    async addOffers ({ commit, state, rootState }, offers) {
      const accessToken = await window.auth0Client.getTokenSilently()
      const env = rootState.site.env.id
      const endpoint = state.api.endpoints[env]

      const request = {
        method: 'POST',
        url: `${endpoint}/offers`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        data: {}
      }

      const promises = []
      offers.forEach(offer => {
        request.data = offer
        promises.push(axios(request).then(response => response.data))
      })
      await Promise.all(promises)

      // On error: {"errors":[{"message":"Incorrect integer value: 'TRUE' for column 'active' at row 1"}]}
      // On success: {"data":{"id":3191,"name":"TST-001","category":"Multimedia","product":"Movies","landing_page":"https://register.test.com?lander=mo","daily_sales":"20","weekly_sales":"0","monthly_sales":"0","blocked_geos":"AI,BB,BR,CN,DZ,GE,GW,IN,UA","conversion_type":"CPA","currency":"USD","base_price":"10.6","geo_price":"AD:4,AE:8,AG:5,AM:0,AP:4,AR:5,AS:6,AT:16,AU:0,AW:8,AZ:11,BD:15,BE:12,BG:1,BH:19,BM:14,BN:15,BO:12,BR:13,BT:1,BY:9,BZ:4,CA:14,CH:8,CK:5,CL:10,CO:2,CR:13,CY:13,CZ:9,DE:10,DK:0,DM:1,DO:18,EC:8,EE:5,ES:5,FI:9,FJ:8,FR:8,GB:3,GD:3,GR:16,GT:8,GU:13,GY:0,HK:2,HN:5,HR:9,HT:12,HU:3,IE:5,IN:18,IS:0,IT:3,JM:4,JO:7,JP:9,KH:17,KN:19,KR:1,KW:4,KY:9,KZ:4,LA:2,LB:12,LC:8,LK:9,LT:1,LU:1,LV:10,MA:3,MD:2,ME:1,MM:8,MN:9,MO:14,MT:9,MU:12,MV:5,MW:17,MX:11,NI:6,NL:2,NO:13,NP:9,NZ:7,OM:6,PA:0,PE:3,PG:5,PH:1,PL:0,PR:8,PS:16,PT:8,PY:17,QA:11,RS:16,RU:3,SA:17,SE:13,SG:13,SI:1,SK:6,SV:16,TC:4,TH:7,TR:17,TT:0,TW:9,US:12,UY:18,UZ:7,VC:17,VG:1,VI:4,VN:6,WS:9,YE:16,ZA:5","pricelist_id":"1","ap":"1","offer_id":"1","active":"1","start_date":"2022-06-20T00:00:00.000Z","end_date":"2022-07-20T00:00:00.000Z"}}
    },

    async getPrices ({ commit, state, rootState }, { limit = 10, offset = 0, sortBy = null, sortDirection = 'asc', q = null }) {
      const accessToken = await window.auth0Client.getTokenSilently()
      const env = rootState.site.env.id
      const endpoint = state.api.endpoints[env]

      const qParams = []
      // if (country && country.length > 0) qParams.push('country=' + country)
      qParams.push('limit=' + limit)
      qParams.push('offset=' + offset)
      if (sortBy) {
        qParams.push(`order=[["${sortBy}", "${sortDirection}"]]`)
      }
      let query = ''
      if (qParams.length > 0) {
        query = '?' + qParams.join('&')
      }

      const request = {
        method: 'GET',
        url: `${endpoint}/prices${query}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      }

      return axios(request).then(response => { return { data: response.data.data, total: response.headers['x-total-count'] } })
    },

    async getPricesAsExcel ({ commit, state, rootState }, { q = null }) {
      const accessToken = await window.auth0Client.getTokenSilently()
      const env = rootState.site.env.id
      const endpoint = state.api.endpoints[env]

      const qParams = []
      let query = ''
      if (qParams.length > 0) {
        query = '?' + qParams.join('&')
      }

      const request = {
        method: 'GET',
        url: `${endpoint}/prices.xlsx${query}`,
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          Authorization: `Bearer ${accessToken}`
        }
      }

      return axios(request).then(response => response.data)
    }
  }
}
