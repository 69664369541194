import Vue from 'vue'
import Vuex from 'vuex'
import pathify from 'vuex-pathify'

import auth from './auth'
import dashboard from './dashboard'
import data from './data'
import finauto from './finauto'
import mfb from './mfb'
import mpb from './mpb'
import msb from './msb'
import opti from './opti'
import opti2 from './opti2'
import reddopti from './redd-opti'
import site from './site'

Vue.use(Vuex)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default function (/* { ssrContext } */) {
  const Store = new Vuex.Store({
    modules: {
      auth,
      dashboard,
      data,
      site,
      mfb,
      msb,
      mpb,
      finauto,
      opti,
      reddopti,
      opti2
    },
    plugins: [pathify.plugin],

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEV
  })

  return Store
}
