import { make } from 'vuex-pathify'
import axios from 'axios'
import { Notify, Loading } from 'quasar'

const state = {
  cdn: {
    templates: {
      endpoints: {
        local: 'https://sb-assets-develop.milkbox.dev/spa/template/index.html',
        dev: 'https://sb-assets-develop.milkbox.dev/spa/template/index.html',
        staging:
          'https://sb-assets-staging.milkbox.dev/spa/template/index.html',
        prod:
          'https://sb-assets-production.milkboxsites.com/spa/template/index.html'
      }
    },
    assets: {
      endpoints: {
        local: 'https://sb-assets-develop.milkbox.dev',
        dev: 'https://sb-assets-develop.milkbox.dev',
        staging: 'https://sb-assets-staging.milkbox.dev',
        prod: 'https://sb-assets-production.milkboxsites.com'
      }
    }
  },
  api: {
    portal: {
      endpoints_lb: {
        local: 'https://develop-sb-api-web-lb.milkbox.dev/process',
        dev: 'https://develop-sb-api-web-lb.milkbox.dev/process',
        staging: 'https://staging-sb-api-web-lb.milkbox.dev/process',
        prod: 'https://production-sb-api-web-lb.milkboxsites.com/process'
      },
      endpoints: {
        local: 'https://develop-sb-api-gateway.milkbox.dev/process',
        dev: 'https://develop-sb-api-gateway.milkbox.dev/process',
        staging: 'https://staging-sb-api-gateway.milkbox.dev/process',
        prod: 'https://sb-api-gateway.milkboxsites.com/process'
      }
    },
    templates: {
      endpoints: {
        local: 'https://develop-sb-api-web-lb.milkbox.dev/process',
        dev: 'https://develop-sb-api-web-lb.milkbox.dev/process',
        staging: 'https://staging-sb-api-web-lb.milkbox.dev/process',
        prod: 'https://production-sb-api-web-lb.milkboxsites.com/process'
      }
    },
    generator: {
      endpoints: {
        local: 'https://production-sb-api-home-site-generator.milkboxsites.com',
        dev: 'https://develop-sb-api-home-site-generator.milkbox.dev',
        staging: 'https://staging-sb-api-home-site-generator.milkbox.dev',
        prod: 'https://production-sb-api-home-site-generator.milkboxsites.com'
      }
    },
    reporting: {
      endpoints: {
        local: 'http://localhost:3000',
        dev: 'https://develop-sb-api-reporting.milkbox.dev/auth0',
        staging: 'https://staging-sb-api-reporting.milkbox.dev/auth0',
        prod: 'https://production-sb-api-reporting.milkboxsites.com/auth0'
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters: {
    templateCdnEndpoint (state, getters, rootState, rootGetters) {
      const env = rootState.site.env.id
      return state.cdn.templates.endpoints[env]
    },
    assetsCdnEndpoint (state, getters, rootState, rootGetters) {
      const env = rootState.site.env.id
      return state.cdn.assets.endpoints[env]
    }
  },
  mutations: {
    ...make.mutations(state)
  },
  actions: {
    /**
     * @description Call portal gateway
     */
    async callPortal ({ commit, state, rootState }, { path, body, regular }) {
      body = body || {}

      const env = rootState.site.env.id
      const accessToken = await window.auth0Client.getTokenSilently()
      const endpoint = regular
        ? state.api.portal.endpoints[env]
        : state.api.portal.endpoints_lb[env]
      const axiosInstance = axios.create({
        baseURL: endpoint,
        headers: { 'Milkbox-Authorization': `Bearer ${accessToken}` }
      })

      axiosInstance.interceptors.response.use(
        (response) => {
          return response
        },
        (error) => {
          let msg = error.message
          const status = error.response.status
          if (status === 400) {
            msg = error.response.data.message
          }
          Notify.create({
            message: 'Failed to make request',
            caption: msg,
            type: 'negative'
          })
          Loading.hide()
          return Promise.reject(error)
        }
      )

      return await axiosInstance.post(path, body)
    },

    /**
     *
     * Reporting
     */
    async runReport (
      { dispatch, state, commit, rootState },
      { type, resource, id, payload = null }
    ) {
      const env = rootState.site.env.id
      const token = await window.auth0Client.getTokenSilently()

      if (type === 'run') {
        return axios({
          method: 'POST',
          headers: {
            'Milkbox-Authorization': `Bearer ${token}`
          },
          url: encodeURI(
            `${state.api.reporting.endpoints[env]}/${type}/${resource}`
          ),
          data: payload
        }).then((result) => result.data)
      }

      const compareSiteId = payload.compare_site_id
      let query = `?start=${payload.start_date.toISOString()}&end=${payload.end_date.toISOString()}&include_internal=${
        payload.include_internal
      }`

      if (type === 'report') {
        if (payload.compare_start && payload.compare_end) {
          query += `&compare_start=${payload.compare_start.toISOString()}&compare_end=${payload.compare_end.toISOString()}`
        } else if (compareSiteId) {
          query += `&compare_site_id=${compareSiteId}`
        }
      } else if (type === 'graph') {
        if (compareSiteId) {
          query += `&compare_site_id=${compareSiteId}`
        } else if (payload.compare_start && payload.compare_end) {
          query += `&compare_start=${payload.compare_start.toISOString()}&compare_end=${payload.compare_end.toISOString()}`
        }
        query += `&metric=${payload.metric}&period_in_seconds=${payload.period_in_seconds}`
      } else {
        throw new Error(`Unknown report type [${type}]`)
      }
      return axios({
        method: 'get',
        headers: {
          'Milkbox-Authorization': `Bearer ${token}`
        },
        url: encodeURI(
          `${state.api.reporting.endpoints[env]}/${type}/${resource}/${id}${query}`
        )
      }).then((result) => result.data)
    },
    async runReportAll (
      { dispatch, state, commit, rootState },
      { type, resource, payload = null }
    ) {
      const env = rootState.site.env.id
      const token = await window.auth0Client.getTokenSilently()

      const compareSiteId = payload.compare_site_id
      let query = `?start=${payload.start_date.toISOString()}&end=${payload.end_date.toISOString()}&include_internal=${
        payload.include_internal
      }`

      if (type === 'report') {
        if (payload.compare_start && payload.compare_end) {
          query += `&compare_start=${payload.compare_start.toISOString()}&compare_end=${payload.compare_end.toISOString()}`
        } else if (compareSiteId) {
          query += `&compare_site_id=${compareSiteId}`
        }
      } else if (type === 'graph') {
        if (compareSiteId) {
          query += `&compare_site_id=${compareSiteId}`
        } else if (payload.compare_start && payload.compare_end) {
          query += `&compare_start=${payload.compare_start.toISOString()}&compare_end=${payload.compare_end.toISOString()}`
        }
        query += `&metric=${payload.metric}&period_in_seconds=${payload.period_in_seconds}`
      } else {
        throw new Error(`Unknown report type [${type}]`)
      }
      return axios({
        method: 'get',
        headers: {
          'Milkbox-Authorization': `Bearer ${token}`
        },
        url: encodeURI(
          `${state.api.reporting.endpoints[env]}/${type}/${resource}${query}`
        )
      }).then((result) => result.data)
    },
    async callGenerator ({ commit, state, rootState }, { path, body }) {
      body = body || {}

      const env = rootState.site.env.id
      const accessToken = await window.auth0Client.getTokenSilently()
      const endpoint = state.api.generator.endpoints[env]

      const request = {
        method: 'post',
        url: `${endpoint}${path}`,
        data: body,
        headers: {
          'content-type': 'application/json',
          'Milkbox-Authorization': `Bearer ${accessToken}`
        }
      }
      return await axios(request)
    },
    /**
     *
     * CRUD API Operations
     */
    async fetchAllFromAPI (
      { dispatch, state, commit, rootState },
      {
        name,
        resource,
        limit = 10,
        offset = 0,
        sortBy = null,
        sortDirection = 'asc',
        attributes = null,
        q = null
      }
    ) {
      if (!state.api[name]) {
        throw new Error(`Unknown API [${name}]`)
      }

      const env = rootState.site.env.id
      const token = await window.auth0Client.getTokenSilently()

      return axios({
        method: 'post',
        headers: {
          'Milkbox-Authorization': `Bearer ${token}`
        },
        data: {},
        url: encodeURI(`${state.api.portal.endpoints[env]}/get-templates_base`)
      }).then((result) => {
        return {
          data: result.data.templates,
          total: result.headers['x-total-count']
        }
      })
    },

    async getSpecificFromAPI (
      { dispatch, state, commit, rootState },
      { name, resource, id }
    ) {
      if (!state.api[name]) {
        throw new Error(`Unknown API [${name}]`)
      }

      return this.dispatch('msb/callPortal', {
        method: 'post',
        path: '/get-template_base',
        body: { template_id: id }
      }).then((result) => result.data)
    },

    async updateSpecificFromAPI (
      { dispatch, state, commit, rootState },
      { name, resource, id, data }
    ) {
      if (!state.api[name]) {
        throw new Error(`Unknown API [${name}]`)
      }
      const env = rootState.site.env.id
      const token = await window.auth0Client.getTokenSilently()

      return axios({
        method: 'patch',
        headers: {
          'Milkbox-Authorization': `Bearer ${token}`
        },
        url: encodeURI(`${state.api[name].endpoints[env]}/${resource}/${id}`),
        data: data
      }).then((result) => result.data)
    },

    async deleteFromAPI (
      { dispatch, state, commit, rootState },
      { name, resource, id }
    ) {
      if (!state.api[name]) {
        throw new Error(`Unknown API [${name}]`)
      }
      const env = rootState.site.env.id
      const token = await window.auth0Client.getTokenSilently()

      return axios
        .delete(
          encodeURI(`${state.api[name].endpoints[env]}/${resource}/${id}`),
          {
            headers: { 'Milkbox-Authorization': `Bearer ${token}` }
          }
        )
        .then((result) => result.data)
    }
  }
}
