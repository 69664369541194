import { make } from 'vuex-pathify'
import find from 'lodash/find'
import * as _get from 'lodash/get'
import defaultsDeep from 'lodash/defaultsDeep'
import some from 'lodash/some'
import reject from 'lodash/reject'
import transform from 'lodash/transform'
import * as _set from 'lodash/set'

/* global MC */

const state = {
  charts: {
    dev: {
      endpoint: 'https://charts.mongodb.com/charts-milkbox-dev-ffdpt',
      graphs: {
        brandsOverTime: '03ec1b64-b9a7-41bd-b18e-356394f4c9b5',
        brandsNew: '282ab78d-35ab-4bfe-9020-355d3fe80a56',
        brandsPerIndustry: '86a6e683-3fb1-4798-a514-d053a0b5abd1',
        memberLogins24Hours: '',
        memberLogouts24Hours: '',
        memberPwdResetReq14Days: '',
        membersNew: ''
      }
    },
    staging: {
      endpoint: 'https://charts.mongodb.com/charts-milkbox-staging-ogvpd',
      graphs: {
        brandsOverTime: 'e6693eb0-4093-420a-9102-0d8d7ebaa7cc',
        brandsNew: '9833f9a6-0382-4627-a215-83dedc7d1c29',
        brandsPerIndustry: '3328edce-4e5d-4c7f-ac21-64bf400ee1e7',
        memberLogins24Hours: '',
        memberLogouts24Hours: '',
        memberPwdResetReq14Days: '',
        membersNew: ''
      }
    },
    prod: {
      endpoint: 'https://charts.mongodb.com/charts-milkbox-production-dtlgf',
      graphs: {
        brandsOverTime: 'b1d26407-0892-4595-a681-c5fae7934a46',
        brandsNew: '5c65fbce-2da8-4872-b07e-83a5fe8fb4db',
        brandsPerIndustry: '3aa08b3c-767c-4589-8e7e-ac88d5906598',
        memberLogins24Hours: '99113bc8-b16a-4fc9-9bb2-b89779d2692b',
        memberLogouts24Hours: '82d08a31-bd8f-4b0b-92f4-aa0ce82d5f5a',
        memberPwdResetReq14Days: '4e1199f7-9242-4b23-9b62-adfcb2fa924e',
        membersNew: '4b0e3a12-3658-4753-bbe4-853d3ea69693'
      }
    }
  },
  editMode: false,
  currentWid: '',
  layout: {
    dashboard: [],
    airtable: [],
    canvas: [],
    domo: [],
    experiments: []
  },
  widgets: [
    {
      id: 'welcome',
      component: 'WidgetWelcome',
      config: {}
    }
  ],
  ref: {
    WidgetWelcome: {
      isResizable: false,
      defaults: {
        w: 2,
        h: 2
      }
    },
    WidgetAirtable: {
      defaults: {
        w: 6,
        h: 4
      }
    },
    WidgetCanvas: {
      defaults: {
        w: 2,
        h: 3
      }
    },
    WidgetDomo: {
      defaults: {
        w: 2,
        h: 3
      }
    },
    WidgetExperiment: {
      defaults: {
        w: 6,
        h: 2
      }
    },
    WidgetMemberSearch: {
      defaults: {
        w: 2,
        h: 1
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations: {
    ...make.mutations(state),

    setInitialLayout (state, value) {
      state.layout = defaultsDeep(value, {
        dashboard: [{ x: 0, y: 0, w: 2, h: 2, id: 'welcome' }],
        airtable: [],
        canvas: [],
        domo: [],
        experiments: []
      })
    },

    updateWidget (state, { id, component, config }) {
      const wd = find(state.widgets, ['id', id])
      if (wd) {
        wd.config = config
      } else {
        state.widgets.push({
          id,
          component,
          config
        })
      }
    },

    pinWidget (state, wid) {
      if (some(state.layout.dashboard, ['id', wid])) {
        console.warn(`The widget ${wid} is already pinned to the dashboard!`)
        return
      }

      const widgetConf = find(state.widgets, ['id', wid])
      if (!widgetConf) {
        console.warn(`No widget configuration exists for ${wid}!`)
        return
      }

      const widgetRef = _get(state.ref, widgetConf.component, { defaults: {} })

      state.layout.dashboard.push({
        x: 0,
        y: 0,
        ...widgetRef.defaults,
        id: wid
      })
    },

    unpinWidget (state, wid) {
      if (!some(state.layout.dashboard, ['id', wid])) {
        console.warn(`The widget ${wid} is not pinned on the dashboard!`)
        return
      }

      state.layout.dashboard = reject(state.layout.dashboard, ['id', wid])
    },

    deleteWidget (state, id) {
      state.layout = transform(state.layout, (result, value, key) => {
        _set(result, key, reject(value, ['id', id]))
      }, {})
      state.widgets = reject(state.widgets, ['id', id])
    }
  },
  actions: {
    async persist (context) {
      await MC.$options.auth.updateUserMeta({
        layout: context.state.layout,
        widgets: context.state.widgets
      })
      context.commit('SET_EDIT_MODE', false)
    }
  }
}
