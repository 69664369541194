export default [
  {
    key: 'mcs',
    title: 'Customer support center',
    icon: 'img:icons/fluent/technical-support.svg',
    path: 'msb',
    permissions: [
      'role:admin',
      'role:cs-agent'
    ],
    children: [
      {
        key: 'mcs-searchdesk',
        path: 'mcs/searchdesk/members',
        title: 'Search Desk',
        icon: 'img:icons/fluent/filter.svg'
      },
      {
        key: 'zendesk',
        path: 'zendesk',
        title: 'Zendesk',
        icon: 'img:icons/zendesk.svg',
        url: 'https://milkboxhelp.zendesk.com/agent/dashboard'
      }
    ]
  }
]
