export default [
  {
    key: 'msb',
    title: 'Site Builder',
    icon: 'img:icons/fluent/web-design.svg',
    path: 'msb',
    permissions: ['role:admin'],
    children: [
      {
        key: 'msb-templates',
        path: 'msb/templates',
        title: 'Templates',
        icon: 'img:icons/fluent/resume-template.svg'
      },
      {
        key: 'msb-recipes',
        path: 'msb/recipes',
        title: 'Recipes',
        icon: 'img:icons/recipes.svg'
      },
      {
        key: 'msb-sections',
        path: 'msb/sections',
        title: 'Sections',
        icon: 'img:/icons/fluent/dashboard-layout.svg'
      },
      {
        key: 'msb-components',
        path: 'msb/components',
        title: 'Components',
        icon: 'img:icons/recipes.svg'
      },
      {
        key: 'msb-content',
        path: 'msb/content',
        title: 'Content',
        icon: 'img:icons/wiki.svg'
      },
      {
        key: 'msb-sites',
        path: 'msb/sites',
        title: 'Sites',
        icon: 'img:/icons/fluent/web-page.svg'
      },
      {
        key: 'msb-payments',
        path: 'msb/payments',
        title: 'Payments',
        icon: 'img:/icons/fluent/cashless-payment.svg'
      },
      {
        key: 'msb-publishing',
        path: 'msb/publishing',
        title: 'Publishing',
        icon: 'img:/icons/fluent/upload-link-document.svg'
      },
      {
        key: 'msb-monitoring',
        path: 'msb/metrics',
        title: 'Home Sites Metrics',
        icon: 'img:/icons/fluent/server.svg'
      }
    ]
  }
]
